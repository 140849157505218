<template>
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <img src="/assets/layout/images/logo-dark.svg" class="logo" alt="diamond-layout"/>
                <form @submit.prevent="handleSubmit">
                    <div class="login-form">
                        <h2>Войти</h2>
                        <p>Нет аккаунта?
                            <router-link :to="{name : 'register'}">Регистрация</router-link>
                        </p>
                        <div class="field">
                            <span class="p-input-icon-left">
                                <i class="pi pi-envelope"/>
                                <InputText id="email" v-model="form.fields.email"
                                           type="email"
                                           placeholder="Е-майл"
                                           :area-invalid="form.errors.email ? true : null"
                                           :aria-describedby="form.errors.email ? form.errors.email : null"
                                           :class="{ 'p-invalid' : form.errors.email }"/>
                            </span>
                            <div v-if="form.errors.email" class="p-error text-left"><small>{{ form.errors.email
                                }}</small></div>
                        </div>
                        <div class="field">
                            <span class="p-input-icon-left">
                                <i class="pi pi-lock"/>
                                <InputText id="password" v-model="form.fields.password"
                                           type="password"
                                           placeholder="Пароль"
                                           :area-invalid="form.errors.password ? true : null"
                                           :aria-describedby="form.errors.password ? form.errors.password : null"
                                           :class="{ 'p-invalid' : form.errors.password }"/>
                            </span>
                            <div v-if="form.errors.password" class="p-error text-left"><small>
                                {{ form.errors.password }}</small></div>
                        </div>
                        <Button label="ВОЙТИ" type="submit" :loading="loginLoading"></Button>
                    </div>
                </form>

                <p>Забыли пароль?
                    <router-link :to="{name : 'forgot-password'}">Нажмите сюда</router-link>
                    для восстановления
                </p>
            </div>
            <div class="login-image">
                <div class="login-image-content">
                    <h1>Access to your</h1>
                    <h1>Diamond</h1>
                    <h1>Account</h1>
                    <h3>
                        Lorem ipsum dolor sit amet, consectetur <br/>
                        adipiscing elit. Donec posuere velit nec enim <br/>
                        sodales, nec placerat erat tincidunt.
                    </h3>
                </div>
                <div class="image-footer">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div class="icons">
                        <i class="pi pi-github"></i>
                        <i class="pi pi-twitter"></i>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
            v-model:visible="showTwoFactorAuthenticationModal"
            :style="{width: '450px'}"
            :modal="true"
            header="Подтверждение действия"
            class="p-fluid">
            <div class="field grid">
                <label for="code" class="col-12 mb-2">
                    <i class="pi pi-mobile mr-2"/>
                    Введите код с устройства
                </label>
                <div class="col-12 mb-3">
                    <InputText id="code"
                               v-model="form.fields.two_factor_code"
                               placeholder="XXXXXXXX"
                               :area-invalid="form.errors.two_factor_code ? true : null"
                               :aria-describedby="form.errors.two_factor_code ? form.errors.two_factor_code : null"
                               :class="{ 'p-invalid' : form.errors.two_factor_code }"/>
                    <div v-if="form.errors.two_factor_code"
                         class="p-error text-left">
                        <small>{{ form.errors.two_factor_code
                            }}</small>
                    </div>
                </div>
                <div class="col-12">
                    <Button
                        label="Подтвердить"
                        :loading="loginLoading"
                        @click="validateTwoFactorAuthentication"
                    />
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { reactive, computed, ref } from 'vue'
import ToastMessageService from '../services/ToastMessageService'
import AuthService from '../services/AuthService'
import FormService from '../services/FormService'
import router from '../router'
import { useStore } from 'vuex'

export default {
    setup () {
        const store = useStore()
        const formService = new FormService()
        const authService = new AuthService()
        const toastMessageService = new ToastMessageService()
        const showTwoFactorAuthenticationModal = ref(false)
        const defaultForm = () => {
            return {
                fields: {
                    email: null,
                    password: null
                },
                errors: {}
            }
        }
        const form = reactive(defaultForm())

        const resetForm = () => {
            Object.assign(form, defaultForm())
        }

        const loginLoading = computed(() => store.state.auth.loginLoading || store.state.auth.userLoading)
        const validateTwoFactorAuthentication = () => {
            handleSubmit()
        }
        const handleSubmit = async () => {
            await authService.login(form.fields).then(async () => {
                resetForm()
                showTwoFactorAuthenticationModal.value = false
                await router.push({ name: 'addNewBoard' })
                await toastMessageService.add({
                    detail: 'Добро пожаловать'
                })
            }).catch(async (exception) => {
                if (exception.response && exception.response.status === 423) {
                    Object.assign(form.fields, { two_factor_code: null })
                    showTwoFactorAuthenticationModal.value = true
                } else {
                    formService.fillErrors(exception, form)
                }
            })
        }

        return {
            form,
            resetForm,
            handleSubmit,
            loginLoading,
            showTwoFactorAuthenticationModal,
            validateTwoFactorAuthentication
        }
    }
}
</script>
